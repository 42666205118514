import {acceptHMRUpdate, defineStore} from 'pinia';
import {computed, ref} from 'vue';
import type {ProblemDetail} from '~/lib/types/fetchErrors';
import useApi from '~/composables/useApi';
import type {components} from '~/lib/types/openapi';

type Authority = components['schemas']['Authority'];
type ContextInfo = components['schemas']['ContextInfo'];
type RieInfo = components['schemas']['RieInfo'];

export const useCompanyContextStore = defineStore('companyContext', () => {
	const {GET} = useApi();
	const loading = ref(false);
	const problemDetail = ref<ProblemDetail>();
	const companyContext = ref<ContextInfo>();

	const currentCompanyId = ref<string>();
	const currentRieId = ref<number>();

	const fetchCompanyContext = async (companyId: string) => {
		if (companyId !== currentCompanyId.value) {
			companyContext.value = undefined;
		}
		currentCompanyId.value = companyId;

		loading.value = true;
		problemDetail.value = undefined;

		const {data, error} = await GET(`/rie/company/{companyId}`, {
			params: {
				path: {
					companyId
				}
			}
		});

		if (error || !data) {
			loading.value = false;
			problemDetail.value = error;
			return false;
		}

		companyContext.value = data;
		loading.value = false;
		return true;
	};

	const getCompanyName = computed(() => (companyId?: string) => {
		if (!companyId) {
			return undefined;
		}
		if (companyId === companyContext.value?.companyInfo?.registrationCode) {
			return companyContext.value?.companyInfo.name;
		}
		return '';
	});

	const getRieName = computed(() => (companyId?: string, rieId?: number) => {
		if (!companyId || !rieId) {
			return undefined;
		}

		if (companyId === currentCompanyId.value) {
			return companyContext.value?.ries?.find((rie: components['schemas']['RieInfo']) => rie.id === rieId)?.name;
		}

		return undefined;
	});

	const writeAccessForCurrentCompany = computed(() => {
		return companyContext.value?.companyInfo?.authorities
			?.some((authority: Authority) => authority.permission === 'WRITE') ?? false;
	});

	const writeAccessForCurrentRie = computed(() => {
		return companyContext.value?.ries
			?.find((rie: RieInfo) => rie.id === currentRieId.value)?.authorities
			?.some((authority: Authority) => authority.permission === 'WRITE') ?? false;
	});

	return {
		fetchCompanyContext,
		getCompanyName,
		getRieName,
		writeAccessForCurrentCompany,
		writeAccessForCurrentRie,
		currentCompanyId,
		currentRieId,
		companyContext,
		problemDetail,
		loading
	};
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useCompanyContextStore, import.meta.hot));
}
